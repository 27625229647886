import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() img : String = "";
  @Input() type : String = "";
  @Input() title : String = "";
  @Input() text : String = "";
  @Input() time : String = "";
  @Input() textLink : String = "";
  @Input() routerLinkParam : String = "";
  @Input() externalLinkParam = "";


  public isHover = false;

  constructor(private router: Router) { }

  ngOnInit() {
    return null;
  }

  navigate() {
    console.log(this.externalLinkParam);
    if (this.routerLinkParam) this.router.navigate([this.routerLinkParam]);
    else if (this.externalLinkParam) window.open(this.externalLinkParam, "_blank");
  }


}
