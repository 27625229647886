import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { Component, ContentChildren, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';


const left = [
  group([
    query(':enter', [style({ transform: 'translateX(-100%)' }), animate('1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275)', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275)', style({ transform: 'translateX(100%)' }))], {
      optional: true,
    }),
    query(':enter', [style({ opacity: 0 }), animate('.3s ease-in', style({ opacity: 1 }))], {optional: true,}),
    query(':leave', [style({ opacity: 1 }), animate('.3s ease-in', style({ opacity: 0 }))], {optional: true,}),
  ]),
];

const right = [
  group([
    query(':enter', [style({ transform: 'translateX(100%)' }), animate('1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275)', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275)', style({ transform: 'translateX(-100%)' }))], {
      optional: true,
    }),
    query(':enter', [style({ opacity: 0 }), animate('.3s ease-in', style({ opacity: 1 }))], {optional: true,}),
    query(':leave', [style({ opacity: 1 }), animate('.3s ease-in', style({ opacity: 0 }))], {optional: true,}),
  ]),
];


@Component({
  selector: 'app-carousel-card',
  template: `
   <ng-template #innerTemplate><ng-content></ng-content></ng-template>
  `,
})
export class CarouselMultipleCardComponent {
  @ViewChild('innerTemplate', {static: true})
  public innerTemplate: TemplateRef<any>;
}


@Component({
  selector: 'app-carousel-multiple',
  templateUrl: './carousel-multiple.component.html',
  styleUrls: ['./carousel-multiple.component.scss'],
  animations: [
    trigger('animSlider', [
      transition(':increment', right),
      transition(':decrement', left),
    ]),
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            animate('0.3s ease-out', style({opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            animate('0.3s ease-in', style({opacity: 0 }))
          ]
        )
      ]
    )
  ],
})
export class CarouselMultipleComponent implements OnInit {
  position: number = 0;
  @ContentChildren(CarouselMultipleCardComponent) cards!: QueryList<CarouselMultipleCardComponent>;


  constructor() { }

  ngOnInit() {
    return null;
  }
  next(){
    if (this.position != this.cards.length - 1) {
      this.position++;
    }
  }

  previous(){
    if (this.position > 0) {
      this.position--;
    }
  }
}
