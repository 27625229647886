

<div class="relative carrousel">
  <div class="bg relative ml-auto  mr-auto w-full">

    <div [@animSlider]="position" class="item-container" position>
      <ng-container *ngFor="let card of cards; let i=index">
        <div *ngIf="i===position" class="item">
          <ng-container [ngTemplateOutlet]="card.innerTemplate"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <div @inOutAnimation *ngIf="position > 0" class="arrows absolute top-1/2 left-0 transform -translate-y-1/2 -translate-x-1/2 button" (click)="previous()">
    <img src="assets/icon/carrousel-chevron-left.svg"/>
  </div>
  <div @inOutAnimation *ngIf="position != cards.length-1" class="arrows absolute top-1/2 right-0 transform -translate-y-1/2 translate-x-1/2 button" (click)="next()">
    <img src="assets/icon/carrousel-chevron-right.svg"/>
  </div>


</div>


  

