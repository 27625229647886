import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() type = "";
  @Input() label = "Button";
  @Input() icon = "";
  @Input() hideArrow = false;
  @Output() clickEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {
    return null;
  }

}
