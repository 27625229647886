<div *ngIf="type == 'rrss'" class="card rrss text-primary hover:text-blue" (click)="navigate()">
  <img [src]="img" class="mb-6">
  <h3 class="font-semibold mb-2">{{title | ellipse:40}}</h3>
  <p class="text-opacity-75 mb-2.5">{{text | ellipse:120}}</p>
  <div class="flex justify-between">
    <img class="linkedin-primary" src="/assets/rrss/linkedin-primary.svg">
    <img class="linkedin-blue" src="/assets/rrss/linkedin-blue.svg">
    <span class="text-primary text-opacity-75">{{time}}</span>
  </div>
</div>

<a *ngIf="type == 'notice'" class="flex flex-col justify-center card notice cursor-pointer" (mouseover)='isHover = true' (mouseout)='isHover = false'>
  <p class="text-primary text-opacity-75 mb-6">{{time}}</p>
  <h3 class="text-primary text-base font-bold mb-2">{{title | ellipse:40}}</h3>
  <p class="text-primary text-opacity-75 mb-2">{{text | ellipse:90}}</p>
  <app-link [text]="textLink" [notice]="true" [routerLinkParam]="routerLinkParam" color="text-blue" [forceImg]="isHover"></app-link>
</a>

<div *ngIf="type == 'resource'" class="flex flex-col justify-center card resource">
  <h3 class="text-sm text-primary font-semibold mb-4">{{title | ellipse:90}}</h3>
  <img class="mb-7" [src]="img">
  <app-link [text]="textLink" [routerLinkParam]="routerLinkParam" color="text-blue"></app-link>
</div>
