import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  @Input() logo = "";
  @Input() title = "";
  @Input() text = "";
  @Input() textLink = "";
  @Input() routerLinkParam = "";
  @Input() externalLinkParam = "";
  @Input() type = "";
  @Input() height = '270px';
  @Input() icon = true;

  public isHover = false;

  constructor(private router: Router) { }

  ngOnInit() {
    return null;
  }

  navigate() {
    console.log(this.externalLinkParam);
    if (this.routerLinkParam) this.router.navigate([this.routerLinkParam]);
    else if (this.externalLinkParam) window.open(this.externalLinkParam, "_blank");
  }

}