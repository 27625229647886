<div class="outer p-5">
  <div class="inner grid grid-cols-2 gap-5 p-6">
    <div>
      <div class="flex mb-14"><img src="/assets/icon/bulletMegaMenu.svg" class="mr-4"><span class="title text-primary">FOR MERCHANTS</span></div>
      <div class="flex flex-col">
        <app-link class="mb-5" text="Getnet" routerLinkParam="/solutions/merchant/getnet" (click)="closeEvent.emit()"></app-link>
        <span class="desc text-grey">Global merchant platform services to create opportunities for merchants worldwide.</span>
      </div>
    </div>

    <div>
      <div class="flex mb-14"><img src="/assets/icon/bulletMegaMenu.svg" class="mr-4"><span class="title text-primary">FOR TRADE</span></div>
      <div class="flex flex-col mb-5">
        <app-link class="mb-5" text="PagoNxt Trade" routerLinkParam="/solutions/trade/pagonxt-trade" (click)="closeEvent.emit()"></app-link>
        <span class="desc text-grey">A global platform to power global businesses.</span>
      </div>
      <div class="flex flex-col mb-5">
        <app-link class="mb-5" text="Payments Hub" routerLinkParam="/solutions/trade/payments-hub" (click)="closeEvent.emit()"></app-link>
        <span class="desc text-grey">Single connection to a 24/7 world of local payments.</span>
      </div>
      <div class="flex flex-col mb-5">
        <a class="flex mb-5" href="https://ebury.com/" target="_blank"><span class="subtitle text-primary hover:text-blue mr-3.5">Ebury</span><img style="width: 15px" src="assets/icon/external-link.svg"></a>
        <span class="desc text-grey">Financial solutions to simplify international trade.</span>
      </div>
<!--       <div class="flex flex-col">
        <a class="externalLink flex mb-5" href="https://mercury-tfs.com/" target="_blank"><span class="subtitle text-primary hover:text-blue mr-3.5">Mercury</span><img style="width: 15px" src="assets/icon/external-link.svg"></a>
        <span class="desc text-grey">Traditional Trade Finance made modern, intuitive and user friendly for banks.</span>
      </div> -->
    </div>

<!--     <div>
      <div class="flex mb-14"><img src="/assets/icon/bulletMegaMenu.svg" class="mr-4"><span class="title text-primary">FOR CONSUMERS</span></div> -->
      <!-- <div class="flex flex-col mb-5">
        <app-link class="mb-5" text="PagoFX" routerLinkParam="/solutions/consumer/pagofx" (click)="closeEvent.emit()"></app-link>
        <span class="desc text-grey">Send money abroad when and how you need to.</span>
      </div> -->
     <!--  <div class="flex flex-col">
        <app-link class="mb-5" text="Superdigital" routerLinkParam="/solutions/consumer/super-digital" (click)="closeEvent.emit()"></app-link>
        <span class="desc text-grey">Successfully bringing the opportunity of financial wellbeing to the unbanked across Latin America.</span>
      </div>
    </div> -->
  </div> 
</div>
