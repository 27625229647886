import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vertical-line',
  templateUrl: './vertical-line.component.html',
  styleUrls: ['./vertical-line.component.scss']
})
export class VerticalLineComponent implements OnInit {

  @Input() color = "#FFFFFF";
  @Input() height = '';


  constructor() { }

  ngOnInit() {
    return null;
  }

}
