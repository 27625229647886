import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss']
})
export class HeaderMainComponent implements OnInit {

  @Input() mainTitle = '';
  @Input() subtitle = '';
  @Input() type = '';
  @Input() animated = false;
  @Input() animatedText = [];

  public heightOnResponsive = '1200px'
  public screenSize

  constructor() { }

  ngOnInit() {
    this.screenSize = window.innerWidth;
    this.calculateHeight(window.innerWidth)
  }

  // RESPONSIVE
  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.screenSize = event.target.innerWidth
      this.calculateHeight(event.target.innerWidth)
  }

  // RESPONSIVE
  calculateHeight( width) {    
    switch(true) {
      case width < 1600 && width > 1280:
        this.heightOnResponsive = `${1140 - ((1600 - width) * 0.5) }px`
        break;
      case width > 1600:
        this.heightOnResponsive = '1200px'
        break;
      case width < 1280:
        this.heightOnResponsive = '1150px'
        break;
      default:
    }
  }

}
