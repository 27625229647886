import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from './core/button/button.component';
import { BannerComponent } from './core/banner/banner.component';
import { HeaderMainComponent } from './core/header-main/header-main.component';
import { HeaderTinyComponent } from './core/header-tiny/header-tiny.component';
import { LinkComponent } from './core/link/link.component';
import { MegaMenuComponent } from './core/navbar/mega-menu/mega-menu.component';
import { VerticalLineComponent } from './core/vertical-line/vertical-line.component';
import { HorizontalBarComponent } from './core/horizontal-bar/horizontal-bar.component';
import { CardComponent } from './core/card/card.component';
import { UserProfileComponent } from './core/user-profile/user-profile.component';
import { MenuSecondaryComponent } from './core/menu-secondary/menu-secondary.component';
import { SwitchComponent } from './core/switch/switch.component';
import { CarouselMultipleCardComponent, CarouselMultipleComponent } from './core/carousel-multiple/carousel-multiple.component';
import { CookiesComponent } from './core/cookies/cookies.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TooltipDirective } from './directives/tooltip.drective';
import { EllipsePipe } from './pipes/ellipse.pipe';


@NgModule({
  declarations: [
    TooltipDirective,
    HeaderMainComponent,
    HeaderTinyComponent,
    BannerComponent,
    VerticalLineComponent,
    HorizontalBarComponent,
    MegaMenuComponent,
    LinkComponent,
    ButtonComponent,
    CardComponent,
    UserProfileComponent,
    MenuSecondaryComponent,
    SwitchComponent,
    CarouselMultipleComponent,
    CookiesComponent,
    CarouselMultipleCardComponent,
    EllipsePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
  ],
  exports:  [
    TooltipDirective,
    HeaderMainComponent,
    HeaderTinyComponent,
    BannerComponent,
    VerticalLineComponent,
    HorizontalBarComponent,
    MegaMenuComponent,
    LinkComponent,
    ButtonComponent,
    CardComponent,
    UserProfileComponent,
    MenuSecondaryComponent,
    SwitchComponent,
    CarouselMultipleComponent,
    CookiesComponent,
    CarouselMultipleCardComponent
  ]
})
export class SharedModule { }
