<div class="card" *ngIf="!emptyCard">
  <img class="photo mb-8" [src]="img">
  <h3 class="text-left text-xs sm:text-base text-primary sm:px-3 mb-4">{{name}}</h3>
  <p class="text-left text-xxxs sm:text-xs text-primary text-opacity-50 leading-5 sm:px-3" >{{position}}</p>
  <div class="flex gap-x-4 mt-3 sm:m-3">
    <a class="rrss" *ngIf="santanderURL" [href]="santanderURL" target="_blank">
      <img class="normal" src="assets/rrss/santander.svg">
      <img class="hover" src="assets/rrss/santander-red.svg">
    </a>
    <a class="rrss" *ngIf="linkedInURL" [href]="linkedInURL" target="_blank">
      <img class="normal" src="assets/rrss/linkedin-primary.svg" >
      <img class="hover"src="assets/rrss/linkedin-blue.svg">
    </a>
  </div>
</div>

<div class="card empty" *ngIf="emptyCard">
  <img class="photo mb-8" [src]="img">
  <h3 class="text-left text-xs sm:text-base text-primary sm:px-3 mb-4">{{name}}</h3>
  <p class="text-left text-xxxs sm:text-xs text-primary text-opacity-50 leading-5 sm:px-3" >{{position}}</p>
  <div class="flex gap-x-4 mt-3 sm:m-3">
    <a class="rrss" *ngIf="santanderURL" [href]="santanderURL" target="_blank">
      <img class="normal" src="assets/rrss/santander.svg">
      <img class="hover" src="assets/rrss/santander-red.svg">
    </a>
    <a class="rrss" *ngIf="linkedInURL" [href]="linkedInURL" target="_blank">
      <img class="normal" src="assets/rrss/linkedin-primary.svg" >
      <img class="hover"src="assets/rrss/linkedin-blue.svg">
    </a>
  </div>
</div>