import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  private currentUrlSubject: Subject<string> = new Subject<string>();

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateCurrentUrl(event.url);
      }
    });
  }

  public updateCurrentUrl(url: string) {
    this.currentUrlSubject.next(url);
  }

  getCurrentUrl(): Observable<string> {
    return this.currentUrlSubject.asObservable();
  }
}