import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-menu-secondary',
  templateUrl: './menu-secondary.component.html',
  styleUrls: ['./menu-secondary.component.scss']
})
export class MenuSecondaryComponent implements OnInit {

  @Output() clickEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {
    return null;
  }

  sendEevnt(string){
    this.clickEvent.emit(string)
  }

}
