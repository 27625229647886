import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {

  public show = false;
  public accepted = false;
  public step1 : Boolean = true;
  public step2 : Boolean = false;
  public analyticsCheck = false;

  constructor(private cookieService: CookieService) { }

  ngOnInit() {
    this.show = this.cookieService.get('accepted') != 'true';
    this.accepted = this.cookieService.get('accepted') == 'true';
  }

  acceptAll() {
    this.cookieService.set('accepted', 'true');
    this.cookieService.set('date', new Date().getTime().toString());
    this.cookieService.set('analytics', 'true');
    this.accepted = true;
  }

  accept() {
    this.cookieService.set('accepted', 'true');
    this.cookieService.set('date', new Date().getTime().toString());
    this.cookieService.set('analytics', this.analyticsCheck.toString());
    this.accepted = true;
  }

}
