import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {

  @Input() type : String = 'light'
  @Input() name : String = 'toogle'
  @Input() isChecked : Boolean = false;
  @Output() value = new EventEmitter();

  constructor() { }

  ngOnInit() {
    return null;
  }

}
