import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UrlService } from '../../url.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public openBurger = false
  public visibleMegaMenu = false;
  public visible = false
  public isSolutionsActive = false;
  public solution = false;


  constructor(private router: Router, private urlService: UrlService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.openBurger = false;
        this.visibleMegaMenu = false;
        this.visible = false
        if (val.url && val.url.includes('/solutions')) this.isSolutionsActive = true;
        else this.isSolutionsActive = false;
        this.urlService.updateCurrentUrl(val.url);
      }
    });
  }

  ngOnInit() {
    return null;
  }

  openMegaMenu() {
    this.visible = true
    setTimeout(() => this.visibleMegaMenu = true, 100);
  }

  closeMegaMenu () {
    this.visible = false
    this.visibleMegaMenu = false;
  }

  leaveOutsideMegaMenu() {
    this.visibleMegaMenu = false;
  }

}
