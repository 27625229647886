import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-tiny',
  templateUrl: './header-tiny.component.html',
  styleUrls: ['./header-tiny.component.scss']
})
export class HeaderTinyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    return null;
  }

}
