<div *ngIf="type == 'product'"
  (click)="navigate()"
  (mouseover)='isHover = true'
  (mouseout)='isHover = false'
  class="flex flex-col items-start justify-center text-sm banner product cursor-pointer"
  [ngStyle]="{'height': height}">
  <img [src]="logo">
  <div class="paragraph text-primary text-opacity-75 text-left flex items-start">{{text}}</div>
  <!-- <a [href]="urlLink" class="text-blue">{{textLink}}</a> -->
  <app-link [text]="textLink" [routerLinkParam]="routerLinkParam" [externalLinkParam]="externalLinkParam" [forceImg]="isHover" color="text-blue"></app-link>
</div>

<div *ngIf="type == 'brand'" (mouseover)='isHover = true' (mouseout)='isHover = false' class="group flex justify-center banner brand">
  <img [src]="logo">
</div>

<div *ngIf="type == 'pages'" (mouseover)='isHover = true' (mouseout)='isHover = false' class="flex flex-col justify-between banner pages text-sm">
  <div class="flex">
    <img class="pr-8" [src]="logo">
    <span class="text-left text-sm xs:text-base sm:text-lg font-semibold">{{title}}</span>
  </div>
  <span class="mr-12 text-left">{{text}}</span>
</div>

<div *ngIf="type == 'blue'" (mouseover)='isHover = true' (mouseout)='isHover = false' class="flex flex-col justify-between banner blue text-sm">
  <div class="flex">
    <img class="pr-8" [src]="logo">
    <span class="text-left text-turquoise text-sm xs:text-base sm:text-lg font-semibold">{{title}}</span>
  </div>
  <span class="mr-8 text-left text-white">{{text}}</span>
</div>

<div *ngIf="type == 'default'" (mouseover)='isHover = true' (mouseout)='isHover = false' (click)="navigate()" class="flex flex-col justify-center items-center banner default text-sm">
  <span class="text-left text-turquoise text-sm md:text-lg lg:text-3xl font-normal text-center mb-4">{{title}}</span>
  <span *ngIf="text" class="text-left text-white text-sm text-center mb-8">{{text}}</span>
  <app-button *ngIf="textLink" type="primary-ghost" [label]="textLink" [icon]="icon ? 'assets/rrss/linkedin.svg' : ''"></app-button>
</div>

<div *ngIf="type == 'default-left'" (mouseover)='isHover = true' (mouseout)='isHover = false' (click)="navigate()" class="flex flex-col sm:flex-row banner default justify-center items-center text-sm">
  <span class="text-center sm:text-left text-turquoise text-sm md:text-lg lg:text-3xl font-semibold mb-3">{{title}}</span>
  <app-button type="primary-ghost" [label]="textLink" class=""></app-button>
</div>
