import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipse'
})
export class EllipsePipe implements PipeTransform {

  transform(value: any, limit: number) {
    console.log(value,limit);
    
    if (value === null) { return ''; }
    if (value.length > limit ) {
      console.log(value.length);
      
      console.log('THIS IS THE VALUE', value.substr(0, limit) + ' ...');
      return value.substr(0, limit) + ' ...';
    
    } else {
      return value;
    }
    
  }

}
