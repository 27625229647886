import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {

  @Input() text = "";
  @Input() notice = false;
  @Input() routerLinkParam = "";
  @Input() externalLinkParam = "";
  @Input() color = "text-primary";
  @Input() forceImg = false;

  constructor(private router: Router) { }

  ngOnInit() {
    return null;
  }

  navigate() {
    if (this.routerLinkParam) {
      this.router.navigate([this.routerLinkParam]);
    } else if (this.externalLinkParam) {
      window.open(this.externalLinkParam, "_blank");
    }
  }
}


