<nav class="text-white">
  <div class="lg:container mx-auto flex items-center justify-between">

    <div class="flex items-center my-8 pl-6  w-28 md:w-40 ">
      <a routerLink="home"> <img src="/assets/logo/logo.svg" alt="logo"></a>
    </div>
    <div class="links-nav flex flex-wrap items-center space-x-4">
      <a routerLink="who-we-are" routerLinkActive="bg-white bg-opacity-20 rounded-md" class="px-3 py-2 hover:bg-white hover:bg-opacity-20">WHO WE ARE</a>
      <a [ngClass]="{'bg-white bg-opacity-20 rounded-md': (isSolutionsActive || visibleMegaMenu)}" class="px-3 py-2 cursor-pointer hover:bg-white hover:bg-opacity-20" (mouseenter)="openMegaMenu()">SOLUTIONS</a>
      <!-- <a routerLink="developers" routerLinkActive="bg-white bg-opacity-20 rounded-md" class="px-3 py-2 hover:bg-white hover:bg-opacity-20 ">DEVELOPERS</a> -->
      <!-- <a routerLink="lab" routerLinkActive="bg-white bg-opacity-20 rounded-md" class="px-3 py-2">LAB</a> -->
      <!-- <a routerLink="financials" routerLinkActive="bg-white bg-opacity-20 rounded-md" class="px-3 py-2">FINANCIALS</a> -->
      <div class="line"></div>
      <!-- <a routerLink="press" routerLinkActive="bg-white bg-opacity-20 rounded-md" class="px-3 py-2 hover:bg-white hover:bg-opacity-20 ">NEWSROOM</a> -->
      <a routerLink="join-us" routerLinkActive="bg-white bg-opacity-20 rounded-md" class="px-3 py-2 hover:bg-white hover:bg-opacity-20 ">CAREERS</a>
    </div>

    <div class="burger flex flex-wrap items-center space-x-4 pr-5">
      <div class="cursor-pointer" (click)="openBurger = !openBurger ; solution = false"  >
        <svg class="burger-an" height="24" width="24" viewBox="0 0 30 30">
          <line [ngClass]="{'burger-line-one': openBurger}" class="burger-line" x1="23" y1="3" x2="0" y2="3" style="stroke:rgb(255, 255, 255);stroke-width:3"></line>
          <line [ngClass]="{'burger-line-two': openBurger}" class="burger-line" x1="23" y1="15" x2="" y2="15" style="stroke:rgb(255, 255, 255);stroke-width:3"></line>
        </svg>
      </div>
      <div class="menu-responsive" [ngClass]="{'open': openBurger}">
        <div class="content_main flex flex-col " [ngStyle]="{'left': !solution ? '35px' : '-200px'}">
          <img src="../../../assets/logo/fullcolor.svg" alt="" width="120px" class="mb-10">
          <a routerLink="home" routerLinkActive="isActive" class="title text-primary font-bold">HOME</a>
          <a routerLink="who-we-are" routerLinkActive="isActive" class="title text-primary font-bold">WHO WE ARE</a>
          <a class="title text-primary font-bold cursor-pointer" (click)="solution = true">SOLUTIONS</a>
          <!-- <a routerLink="developers" routerLinkActive="isActive" class="title text-primary font-bold">DEVELOPERS</a> -->

         <!--  <a routerLink="press" routerLinkActive="isActive" class="title text-primary font-bold mt-10">NEWSROOM</a> -->
          <a routerLink="join-us" routerLinkActive="isActive" class="title text-primary font-bold">JOIN US</a>
        </div>
        <div class="content_sub flex flex-col" [ngStyle]="{'left': solution ? '10px' : '-200px'}">
          <img src="../../../assets/icon/arrow.svg" class="w-4 ml-4 mb-4 cursor-pointer" (click)="solution = false">
          <a class="title text-primary font-bold cursor-pointer">FOR MERCHANTS</a>
          <a routerLink="/solutions/merchant/getnet" routerLinkActive="isActive" class="title text-primary text-xxs ml-5">Getnet</a>
          <a class="title text-primary font-bold cursor-pointer">FOR TRADE</a>
          <a routerLink="/solutions/trade/pagonxt-trade" routerLinkActive="isActive" class="title text-primary text-xxs ml-5">PagoNxt Trade</a>
          <a routerLink="/solutions/trade/payments-hub" routerLinkActive="isActive" class="title text-primary text-xxs ml-5">Payments Hub</a>
          <a href="https://ebury.com/" target="_blank" class="title text-primary text-xxs ml-5 flex mr-2">Ebury<img style="width: 15px" src="assets/icon/external-link.svg" class="ml-3"></a>
          <a href="https://mercury-tfs.com/" target="_blank" class="title text-primary text-xxs ml-5 flex mr-2">Mercury<img style="width: 15px" src="assets/icon/external-link.svg" class="ml-3"></a>

          <a class="title text-primary font-bold cursor-pointer" >FOR CONSUMERS</a>
          <!-- <a routerLink="developers" routerLinkActive="" class="title text-primary text-xxs ml-5">PagoFX</a> -->
          <a routerLink="/solutions/consumer/super-digital" routerLinkActive="isActive" class="title text-primary text-xxs ml-5">Superdigital</a>

        </div>
      </div>
    </div>

  </div>
</nav>

<app-mega-menu *ngIf="visibleMegaMenu" (mouseleave)="leaveOutsideMegaMenu()"  class="flex justify-center"  (onClose)="closeMegaMenu()"></app-mega-menu>
