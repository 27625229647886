<header
  [ngClass]="{'bg-headerMain-pattern': type=='home',
              'bg-headerAbout-pattern': type=='about',
              'bg-headerPagoFx-pattern': type=='pagoFx',
              'bg-headerPaymentsHub-pattern': type=='paymentsHub',
              'bg-headerSuperdigital-pattern': type=='superdigital',
              'bg-headerPress-pattern': type=='press',
              'bg-headerTiny-pattern': type=='tiny',
              'bg-headerOther-pattern': type==''}"
  class=" bg-cover  xl:bg-cover  bg-right overflow-hidden"  [ngStyle]="{'height': type=='tiny' ? '1115px' : heightOnResponsive}">
  <div class="container mx-auto text-white">
    <div style="position:relative">
      <ng-content></ng-content>
    </div>
    <h1 class="text-white text-xl xs:text-2xl lg:text-6xl px-6" [innerHtml]="mainTitle"></h1>
    <div *ngIf="animated" class="content text-secondary font-bold">
      <div class="content__container">
        <ul class="content__container__list">
          <li class="content__container__list__item  text-4xl lg:text-6xl px-6" *ngFor="let text of animatedText">{{text}}</li>
        </ul>
      </div>
    </div>
    <h2 class="text-white px-6 mt-20 text-xs lg:text-sm" [ngStyle]="{'margin-top': animated ? '-100px' : '0px'}" [innerHtml]="subtitle"></h2>
  </div>
</header>
