import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss']
})
export class MegaMenuComponent implements OnInit {

  @Output() closeEvent = new EventEmitter()

  constructor() { }

  ngOnInit() {
    return null;
  }

}
