import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then(
        m => m.HomeModule
      )
  },
  {
    path: 'who-we-are',
    loadChildren: () =>
      import('./pages/who-we-are/who-we-are.module').then(
        m => m.WhoWeAreModule
      )
  },
  {
    path: 'solutions',
    loadChildren: () =>
      import('./pages/solutions/solutions.module').then(
        m => m.SolutionsModule
      )
  },
  {
    path: 'developers',
    loadChildren: () =>
      import('./pages/developers/developers.module').then(
        m => m.DevelopersModule
      )
  },
  // {
  //   path: 'lab',
  //   loadChildren: () =>
  //     import('./pages/developers/developers.module').then(
  //       m => m.DevelopersModule
  //     )
  // },
  // {
  //   path: 'financials',
  //   loadChildren: () =>
  //     import('./pages/financials/financials.module').then(
  //       m => m.FinancialsModule
  //     )
  // },
  {
    path: 'press',
    loadChildren: () =>
      import('./pages/press/press.module').then(
        m => m.PressModule
      )
  },
  {
    path: 'join-us',
    loadChildren: () =>
      import('./pages/join-us/join-us.module').then(
        m => m.JoinUsModule
      )
  },
  {
    path: 'open-channel',
    loadChildren: () =>
      import('./pages/open-channel/open-channel.module').then(
        m => m.OpenChannelModule
      )
  },
  {path: '', redirectTo: '/home',pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
