import { Component, OnInit } from '@angular/core';
import { UrlService } from '../../url.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentUrl: string;
  isJoinUsRoute: boolean;

  constructor(private urlService: UrlService) { }

  ngOnInit() {
    this.urlService.getCurrentUrl().subscribe(url => {
      this.currentUrl = url;
      this.isJoinUsRoute = this.currentUrl && this.currentUrl === '/join-us';
    });
    
    return null;
  }

}
